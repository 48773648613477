<script setup lang="ts">
defineOptions({
  name: 'HomePage',
});

const { $storyblokLivePreview } = useNuxtApp();
const loading = ref({
  loading: true,
});
const classNameRef = ref(['']);
const { getCmsStory, trackPageView, cmsStoryModelRef } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage, getHomepageSchemas } = useMopSeo();
initTransition(classNameRef, loading);

onMounted(async () => {
  await getCmsStory('/');
  trackPageView('Home');
  loading.value.loading = false;
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return;
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead({
  title: () => seoHeadRef.value?.title as any,
  meta: () => seoHeadRef.value?.meta as any,
  link: () => seoHeadRef.value?.link as any,
  script: getHomepageSchemas(),
});
</script>

<template>
  <div :class="['home', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopCmsContentElementsSlot
        v-if="!loading.loading"
        id="homepage__content"
        class="thank-you__content-top"
        :elements="cmsStoryModelRef.getContentElements()"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.home {
  position: relative;
  margin-bottom: $space60;
}
</style>
